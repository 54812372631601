import Link from "../../../../src/components/Link";
import TeachingCards from "../../../../src/components/TeachingCards";
import RecentArticles from "../../../../src/components/RecentArticles";
import PinnedArticles from "../../../../src/components/PinnedArticles";
import * as React from 'react';
export default {
  Link,
  TeachingCards,
  RecentArticles,
  PinnedArticles,
  React
};