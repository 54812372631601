// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-feedback-js": () => import("./../../src/pages/email-feedback.js" /* webpackChunkName: "component---src-pages-email-feedback-js" */),
  "component---src-pages-email-thanks-js": () => import("./../../src/pages/email-thanks.js" /* webpackChunkName: "component---src-pages-email-thanks-js" */),
  "component---src-pages-index-mdx": () => import("./../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-subscribe-js": () => import("./../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-talks-mdx": () => import("./../../src/pages/talks.mdx" /* webpackChunkName: "component---src-pages-talks-mdx" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-writing-js": () => import("./../../src/templates/writing.js" /* webpackChunkName: "component---src-templates-writing-js" */)
}

